import React, {Suspense} from 'react';
import Loading from '../components/global/loading';

const  InlineMailChimpForm = React.lazy(() => import('../components/magazine/inlineMailChimpForm'));
const  Promo = React.lazy(() => import('../components/magazine/promo'));
const  SectionHeading = React.lazy(() => import('../components/corecurriculum/sectionHeading'));
const  RelatedContentCell = React.lazy(() => import('../components/corecurriculum/relatedContentCell'));
const  HorizontalRelatedContentList = React.lazy(() => import('../components/corecurriculum/horizontalRelatedContentList'));
const  VideoCallout = React.lazy(() => import('../components/corecurriculum/videoCallout'));

const corecurriculumMapDataToComponents = (paragraphs, screenWidth, isSection) => {
	const isSSR = typeof window === "undefined";
	return (
		paragraphs &&
		paragraphs.map((p, i) => {
			switch (p.type) {
				case 'section_heading_with_text':
					return ( <SectionHeading key={`curriculumItem${p.type}${i}`} {...p} /> );
				case 'grid_related_content_list':
					return p.groupedItems.map((group, x) => {
						if(screenWidth < 768 ){
							//horizontal scroll small items
							if(x === 1) {
								return (
									<HorizontalRelatedContentList key={`curriculumListItem${p.type}${i}`}>
										{group.map((item, i) => {
											return(<RelatedContentCell key={`curriculumItem${p.type}${x}${i}`} order="1" {...item}/>);
										})}
									</HorizontalRelatedContentList>
								);
							} else {
								return (group.map((item, i) => {
										return(<RelatedContentCell key={`curriculumItem${p.type}${x}${i}`} order="1" {...item}/>);
									})
								);
							}
						} else if(screenWidth <= 1200 && screenWidth >= 768 && group[0].size === 'Small') {
							return (
								<div className="small-group" key={`curriculumListItem${p.type}${x}${i}`} style={{order: "4"}}>
									{group.map((item, i) => {
										return(<RelatedContentCell key={`curriculumItem${p.type}${x}${i}`} order="1" {...item}/>);
									})}
								</div>
							);
						} else {
							return (group.map((item, i) => {
								if(x <= 1 && item.size !== "Large" && paragraphs[0].section_alignment !== 'Center') {
									return(<RelatedContentCell key={`curriculumItem${p.type}${x}${i}`} order="2" {...item}/>);
								} else {
									return(<RelatedContentCell key={`curriculumItem${p.type}${x}${i}`} order="4" {...item}/>);
								}
							}));
						}
					});
				case 'inline_mailchimp_form':
					return (<InlineMailChimpForm key={`curriculumItem${p.type}${i}`} {...p.socialMenu} />);
				case 'grid_text_callout' :
					if(isSection === false) {
						return (<Promo key={`curriculumItem${p.type}${i}`} promo={p} size="large" />);
					} else if(p.image_width_type === 'large') {
						return (<div className="large-promo" key={`curriculumItem${p.type}${i}`}><Promo promo={p} size="large" /></div>);
					} else {
						return (<div className={`medium-promo ${i === 1 ? 'curriculum-spacer' : ''}`} key={`curriculumItem${p.type}${i}`}><Promo promo={p} size="small" /></div>);
					}
				case 'video_callout_simple' :
					if(p.size === 'Large') {
						if(screenWidth > 1200 ){
							return (<div className="large-cell" key={`curriculumItem${p.type}${i}`} style={{ order: "4" }}><VideoCallout {...p} /></div>);
						} else {
							return (<div className="large-cell" key={`curriculumItem${p.type}${i}`}><VideoCallout {...p} /></div>);
						}
					} else {
						return (<div className={`medium-cell ${i === 1 ? 'curriculum-spacer' : ''}`} key={`curriculumItem${p.type}${i}`}><VideoCallout {...p} /></div>);
					}
				default:
					return null;
			}
		})
	).map((paragraph, index) => {
		return(
			<>
			{!isSSR && (
				<Suspense key={`suspense${index}`} fallback={<Loading/>}>
					{paragraph}
				</Suspense>
			)}
			</>
		);
	});
};

export const corecurriculumSectionMap = (paragraphs, screenWidth) => {
	const curriculumSections = paragraphs.reduce((curr, next) => {
		if (next.type === 'section_heading_with_text' || next.type === 'inline_mailchimp_form') {
			curr.push([]);
		}

		const pushDest = curr[curr.length - 1];
		//need to change item display on smaller screens
		const groupedItems = [];
		if(screenWidth <= 1200 && screenWidth >= 768 && next.type === 'grid_related_content_list') {
			let currentGroupIndex = 0;
			next.items.forEach((item, i) => {
				if(i === 0) {
					groupedItems[currentGroupIndex] = [];
				} else if(i > 0 && item.size !== next.items[i - 1].size) {
					currentGroupIndex += 1;
					groupedItems[currentGroupIndex] = [];
				}
				groupedItems[currentGroupIndex].push(item);
			});
			next.groupedItems = groupedItems;
		} else if(screenWidth > 0 && screenWidth < 768 && next.type === 'grid_related_content_list') {
			next.items.sort((a, b) => {
				if (a.size === 'Medium' && (b.size === 'Small' || b.size === 'Large')) {
					return -1;
				} else if (a.size === 'Small' && (b.size === 'Medium' || b.size === 'Large')) {
					return 1;
				} else if (a.size === 'Large' && b.size === 'Medium') {
					return 1;
				} else if (a.size === 'Large' && b.size === 'Small') {
					return -1;
				}
				return 0;
			});
			groupedItems[0] = [];
			groupedItems[1] = [];
			next.items.forEach((item) => {
				if(item.size === 'Small') {
					groupedItems[1].push(item);
				} else {
					groupedItems[0].push(item);
				}
			});
			next.groupedItems = groupedItems;
		} else if(next.type === 'grid_related_content_list') {
			next.groupedItems = [next.items];
		}
		if (typeof pushDest !== 'undefined') {
			pushDest.push(next);
		}
		return curr;
	}, []);

	const sections = curriculumSections.map((sect, i) => {
		if(sect.length > 0 && (sect[0].type === 'resources_callout' || sect[0].type === 'inline_mailchimp_form')) {
			return (<div key={`curriculumSect${i}`}>{corecurriculumMapDataToComponents(sect, screenWidth, false)}</div>);
		} else {
			return (
				<div key={`curriculumSect${i}`} className="curriculum-section">
					{corecurriculumMapDataToComponents(sect, screenWidth, true)}
				</div>
			);
		}
	});
	
	return sections;
};

export default corecurriculumMapDataToComponents;
