import { graphql } from 'gatsby';
import jump from 'jump.js';
import React, { Component } from 'react';
import SectionNavigation from '../components/corecurriculum/sectionNavigation';
import VideoFeatureIntro from '../components/feature/videoFeatureIntro';
import HeadTags from '../components/global/headTags';
import Hero from '../components/global/hero';
import translations from '../utils/translations';
import { corecurriculumSectionMap } from './corecurriculumMapDataToComponents';
import DefaultLayout from '../layouts/default-layout';

class CoreCurriculum extends Component {
	constructor() {
		super();
		this.state = {
			startPage: false,
			isExiting: false,
			width: 0,
		};
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		// issue where first page click doesn't transition properly, so manually doing
		// transition here.
		if (nextProps.transition.status === 'exiting') {
			this.setState({ isExiting: true });
		}
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		this.setState({
			isExiting: false,
		});
		setTimeout(this.startPage, 1000);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth });
	}

	startPage = () => {
		this.setState({ startPage: true });
	};

	render() {
		const {
			title,
			meta_description,
			short_description,
			featured_image,
			featured_video,
			video,
			video_embed,
			paragraphs,
		} = this.props.data.allItemsJson.edges[0].node;

		const exploreClick = () => {
			jump('#content');
		};

		const displayParagraphs = paragraphs.map((paragraph) => {
			paragraph['socialMenu'] = this.props.data.social.edges[0].node;
			return paragraph;
		});

		const sections = paragraphs.filter((paragraph) => paragraph.type === 'section_heading_with_text');

		return (
			<DefaultLayout {...this.props} excludeParentFunctions>
				<div className="curriculum-content-wrapper">
					<HeadTags
						title={title}
						description={meta_description}
						image={featured_image ? featured_image.image.sizes.hero_md : null}
						imageTwitter={
							featured_image ? featured_image.image.sizes.hero_sm : null
						}
						location={this.props.location}
					/>
					{featured_video ? (
						<VideoFeatureIntro
							heading={title}
							description={short_description}
							video={featured_video}
							backgroundImage={featured_image}
							shouldReveal={this.state.startPage}
						/>
					) : (
						<Hero
							label={translations.t('Challenge')}
							title={title}
							short_description={short_description}
							imageSrc={featured_image ? featured_image.image.sizes.hero_lg : null}
							posterSrc={featured_image ? featured_image.image.sizes.hero_md : null}
							videoSrc={video ? video.url : null}
							videoEmbed={video_embed ? video_embed : null}
							shouldReveal={this.state.startPage}
							scrollExplore={exploreClick}
							isCoreCurriculum={true}
							hideExplore={video ? true : false}
						/>
					)}
					<SectionNavigation sections={sections} currentHash={this.props.location.hash} />
					<div id="content">
						{corecurriculumSectionMap(displayParagraphs, this.state.width)}
					</div>
				</div>
			</DefaultLayout>
		);
	}
}

export default CoreCurriculum;

// eslint-disable-next-line
export const corecurriculumPageQuery = graphql`
query corecurriculumPageContentByPath(
	$path: String!
	$menuId: String!
	$isEs: Boolean!
	$idRegex: String!
	$blog: String!
	$translations: String!) {
	allItemsJson(filter: { path: { eq: $path } }) {
	  edges {
		node {
		  path
		  meta_description
		  short_description
		  title
		  related_challenge {
			id
			title
			path
		  }
		  featured_image {
			image {
			  sizes {
				hero_lg
				hero_md
				hero_sm
				original
			  }
			}
		  }
		  featured_video {
			caption
			url
			id
			image {
			  title
			  sizes {
				original
				standard_md
				standard_sm
			  }
			}
		  }
		  video {
			url
		  }
		  video_embed {
			id
			url
			name
		  }
		  ...queryParagraphFields
		}
	  }
	}
	social: allItemsJson(filter: { jsonId: { eq: $menuId } }) {
	  edges {
		node {
		  menu_social {
			title
			menu_name
			weight
			url
			class
			target
		  }
		}
	  }
	}
	...headerFields @skip(if:$isEs)
	...footerFields @skip(if:$isEs)
	...headerFieldsEs @include(if:$isEs)
	...footerFieldsEs @include(if:$isEs)
	challenges: allItemsJson(
		filter: {content_type: {regex: "/timeline|article|challenge/"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			related_challenge {
				title
				path
			}
			core_curriculum {
				title
				path
			}
			}
		}
		}
		pages: allItemsJson(
		filter: {content_type: {eq: "page"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		blog: allItemsJson(filter: {jsonId: {eq: $blog}}) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		translations: allItemsJson(filter: {jsonId: {eq: $translations}}) {
		edges {
			node {
			translations {
				id
				key
				value
			}
			}
		}
	}
	megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
			projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
  }  
`;